// Imports: Dependencies
import * as React from 'react';
import { PageProps } from 'gatsby';

// Imports: Components
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Layout from '../layouts/Layout';
import SEO from '../components/seo';

// Page: Home
const Home: React.FC<PageProps> = (): JSX.Element => (
  <Layout>
    <SEO title="Home" />

    <div id="home-container">
      <NavBar />

      <Hero />

      <Footer />
    </div>
  </Layout>
)

export default Home