// Imports: Dependencies
import React from 'react';
import { Container } from 'react-bootstrap';

// Imports: Component
import ReactTextRotator from '../components/text-rotator/index';

// TypeScript Type: React Text Rotator Content
interface ReactTextRotatorContent {
  text: string,
  className: string,
  animation: string,
};

// Component: Hero
const Hero: React.FC = (): JSX.Element => {
  // React Text Rotator Content
  const content: Array<ReactTextRotatorContent> = [
    {
      text: 'UI Designer',
      className: 'hero-subtitle',
      animation: 'fade',
    },
    {
      text: 'React & React Native Developer',
      className: 'hero-subtitle',
      animation: 'fade',
    },
    {
      text: 'Fullstack TypeScript Engineer',
      className: 'hero-subtitle',
      animation: 'fade',
    },
    {
      text: 'AWS Developer',
      className: 'hero-subtitle',
      animation: 'fade',
    },
  ];

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <h1 className="hero-title">I'm Jeff Lewis.</h1>

        <ReactTextRotator
          content={content}
          time={3500}
          startDelay={0}
        />
      </Container>
    </section>
  );
};

// Exports
export default Hero;
