// Imports: Dependencies
import * as React from 'react';

// Imports: Pages
import Home from './home';

// Imports: Components
import Layout from '../layouts/Layout';

// Page: Index Page
const IndexPage = () => {
  return (
    <Layout>
      <Home />
    </Layout>
  );
};

// Exports
export default IndexPage;
