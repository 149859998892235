// Imports: Dependencies
import { useState, useEffect, useCallback, useRef } from 'react';

// React Hook: Use Rotator
export default ({ content, transitionTime, startDelay, time }) => {
  // React Hooks: State
  const [ isEntered, setEntered ] = useState(false);

  // Item Interval
  let itemInterval;

  // Item Timeout
  let itemTimeout;

  // Display Timeout
  let displayTimeout;

  // Index Ref
  const indexRef = useRef(0);

  // React Hooks: Lifecycle Methods
  useEffect(() => {
    itemTimeout = setTimeout(() => {
      next();
      itemInterval = setInterval(next, time + transitionTime * 2);
    }, startDelay);

    return () =>
      clearTimeout(itemTimeout) &&
      clearInterval(itemInterval) &&
      clearTimeout(displayTimeout);
  }, [content]);

  // Next
  const next = useCallback(() => {
    const itemIndex = indexRef.current;
    const itemContent = content[itemIndex];

    indexRef.current = itemContent ? itemIndex : 0;

    // Set Data
    setEntered(true);

    // Display Timeout
    displayTimeout = setTimeout(() => {
      setEntered(false);
      indexRef.current = indexRef.current + 1;
    }, time);
  }, [content]);

  // Current Item Content
  const currentItemContent = content[indexRef.current];

  return {
    isEntered,
    indexRef,
    currentItemContent,
  };
};
